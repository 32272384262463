import router from '@/router'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { paramsObjectToString } from '@/composables/helper'
import { decreaseDateByHours, localTimeDate } from '@/composables/dateFormat.js'
import { useI18n } from 'vue-i18n'

export function useService() {
    const store = useStore()
    const serviceSlug = router.currentRoute.value.params.slug
    const currentService = computed(() => store.getters['frontend/getCurrentService'](serviceSlug))
    
    store.dispatch('frontend/getServiceBySlug', serviceSlug)
    return { currentService }
}

export function formToQuery(serviceForm) {
    let queryObject = structuredClone(serviceForm)
    
    if (serviceForm.seats && serviceForm.seats.length) {
        for (let seat of serviceForm.seats) {
            queryObject[seat.name.toLowerCase()] = seat.qty
        }
    }

    if (queryObject.date) {
        queryObject.date = queryObject.date.substring(0, 10)
    }

    delete queryObject.seats
    
    return queryObject
}

export function buildServiceRouteUrl(service, purchaseNotes) {
    let baseUrl = `/${service.service_slug}`
    if(!purchaseNotes) {
        return baseUrl
    }

    return `${baseUrl}?${paramsObjectToString(formToQuery(purchaseNotes))}`
}

export function calcPrice(service, purchaseNotes, getGrossPrice = false) {
    let response = 0
    if (purchaseNotes.seats) {
        let priceForSelectedSeats = 0
        for (let option of purchaseNotes.seats) {
            if (option.qty > 0) {
                const optionValue = (option.value_discount > 0 && !getGrossPrice)
                    ? option.value_discount 
                    : option.value
    
                priceForSelectedSeats += option.qty * optionValue
            }
        }

        if (priceForSelectedSeats > 0) {
            response = priceForSelectedSeats
        }
    } else if (purchaseNotes.participants) {
        response = Number(service.value) * purchaseNotes.participants
    }

    if (purchaseNotes.priceType === 'PER_GROUP') {
        response = purchaseNotes.selectedOption.total
    }

    return response
}

export function generateCancelationPolicy(service, purchaseNotes) {
    const { t } = useI18n()
    
    let response = t('site.cart.free_cancellation_not_available')

    const serviceAnnotations = typeof service.annotations === "string"
        ? JSON.parse(service.annotations)
        : service.annotations

    let purchaseDate = purchaseNotes.date
    if (purchaseDate.length === 10) {
        purchaseDate = `${purchaseDate} ${purchaseNotes.hour}`
    }

    if (serviceAnnotations?.free_cancellation > 0 && serviceAnnotations?.cancellation_policy > 0) {
        let freeCancelationDate = decreaseDateByHours(purchaseDate, serviceAnnotations?.cancellation_policy)
        
        if (freeCancelationDate > new Date()) {
            response = t('site.cart.free_cancellation_before') + " " + localTimeDate(freeCancelationDate)
        }
    }

    return response
}

export function mapSeatsKeyVal(seats) {
    let responseObj = {}
    for(let seatOption of seats) {
        responseObj[seatOption.name] = seatOption.qty
    }
    return responseObj
}

export function mapPriceOptionsToSeats(priceOptions, currentSeats = null, minCapacity = 1) {
    const seats = currentSeats ? mapSeatsKeyVal(currentSeats) : null

    return priceOptions?.map(priceOption => {
        let seatQty = seats?.[priceOption.priceType] || 0

        if (!currentSeats && ['Adult', 'Participants'].includes(priceOption.priceType)) {
            seatQty = parseInt(minCapacity ?? 1)
        }

        const extraPrice = seatQty > priceOption.maxCapacity
            ? priceOption.extraPrices.find(price => price.maxCapacity >= seatQty) || {}
            : {}

        return {
            qty: seatQty,
            value: Number(extraPrice.value ?? priceOption.value),
            value_discount: Number(extraPrice.value_discount ?? priceOption.value_discount),
            name: priceOption.priceType,
            ages: priceOption.ageStart ? `(ages ${priceOption.ageStart} - ${priceOption.ageEnd})` : '',
        }
    })
}

export function mapGroupPriceOptionsToSeats(currentSeats = null, minCapacity = 1) {
    const seats = currentSeats ? mapSeatsKeyVal(currentSeats) : {}
    
    return [
        {
            qty: seats['Participants'] || parseInt(minCapacity ?? 1),
            value: null,
            name: 'Participants',
        }
    ];
}